import { AppSettings } from "booking_app/values/app-settings";
import { SearchSortingService } from "booking_app/services/search-sorting.service";
import {
  FlightSortType, MomentDateType, PaymentMethod, ProductType, SortType, Tenant,
  TravelType,
} from "booking_app/types";
import { HermesService } from "booking_app/services/hermes/hermes.service";
import { LoginModalSizeOption, LoginModalWindowClassOption } from "booking_app/types/login-modal-config";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { CityCountryBuilderService } from "booking_app/services/city-country-builder.service";
import "../constants/modal-settings";

declare var angular: any;
declare var moment: any;

angular.module("BookingApp").config([
  "$routeProvider", "$injector",
  ($routeProvider, $injector) => {
    $routeProvider.when("/:travelType", {
      controller: "SearchCtrl",
      templateUrl: "/html/whitelabel/fab/search",
      resolve: {
        parseUrl: $injector.get("ParseTrafficParams"),
      },
    }).when("/company/faqs", {
      redirectTo: "/company/fab-faqs",
    }).when("/company/fab-faqs", {
      controller: "PageCtrl",
      templateUrl: "/html/company/faqs2",
    }).when("terms/terms_condition", {
      redirectTo: "/terms/fab-terms-and-conditions",
    }).when("/terms/fab-terms-and-conditions", {
      controller: "PageCtrl",
      templateUrl: "/html/terms/terms_condition2",
    }).when("/terms/terms_condition", {
      redirectTo: "/terms/fab-terms-and-conditions",
    }).when("/terms/privacy", {
      redirectTo: "/terms/fab-privacy-policy",
    }).when("/terms/fab-privacy-policy", {
      controller: "PageCtrl",
      templateUrl: "/html/terms/privacy_policy2",
    });
  },
]);

class FabController {

  static $inject = [
    "$rootScope",
    "$location",
    "AppSettings",
    "KaligoConfig",
    "SearchSortingService",
    "HermesService",
    "GlobalStateService",
    "CityCountryBuilderService",
  ];

  constructor(
    private $rootScope: any,
    private $location: any,
    private appSettings: AppSettings,
    private kaligoConfig: any,
    private searchSortingService: SearchSortingService,
    private hermesService: HermesService,
    private globalStateService: GlobalStateService,
    private cityCountryBuilderService: CityCountryBuilderService,
  ) {
    this.appSettings.enableChildrenSearch = true;
    this.appSettings.showPerNightText = true;
    this.appSettings.carsListBtnLabel = "Select";
    this.appSettings.mapboxHotelMarkerColor = "#003DA6";
    this.appSettings.logoutUrl = "/whitelabel/fab/logout";
    this.appSettings.tenant = Tenant.FAB;
    this.appSettings.e2eWhitelabel = true;
    this.appSettings.reloadOnQuickSearch = true;

    this.$rootScope.globalState.specialRequestText = "wl.ocbc.special_request_text";
    this.$rootScope.globalState.sessionTimerText = "wl.ocbc.session_timer_text";
    this.$rootScope.globalState.showTotalNights = true;

    this.$rootScope.selectedCurrency = this.$rootScope.currenciesService.findByCode("AED");
    this.$rootScope.convert_rate = this.$rootScope.selectedCurrency.rate;
    this.appSettings.hasCouponCode = true;
    this.appSettings.roomGuestParameters = {
      labelOnlyRoomCounter: false,
      hasRoomGuestCloser: true,
      labelOnlySummaryText: true,
    };
    this.appSettings.pointsCashSliderSettings.hasCustomSliderLabel = true;
    this.appSettings.showCurrencyInFilterSliders = false;
    // FAB can go upto 100% cash. So the ceilingLabelPrefix is set to empty
    this.appSettings.pointsCashSliderSettings.ceilingLabelPrefix = "";
    this.appSettings.pointsCashSliderSettings.alwaysShowPointsSlider = true;
    this.appSettings.pointsCashSliderSettings.pointSliderDefaultValue = 4;
    this.appSettings.pointsCashSliderSettings.incrementPointsSliderCounter = false;
    this.appSettings.customFlightIcon = true;
    this.appSettings.showFlightSelectionState = true;
    this.appSettings.flightSegmentArrivalDisplay = true;
    this.appSettings.defaultAirlineSearchCode = "AUH";
    this.appSettings.showCancelPolicyOnRedeem = true;
    this.appSettings.limitedCountryCityOptions = true;
    this.appSettings.payWithPoints.roundToNearest = 1;
    this.appSettings.loginModalConfig.loginModalWindowClass = LoginModalWindowClassOption.LOGIN_MODAL_VALIDATION_CLASS;
    this.appSettings.loginModalConfig.loginModalSize = LoginModalSizeOption.MEDIUM_SIZE;
    this.appSettings.landingPageUrl = {
      "complimentary-nights": "wl-fab-complimentary_nights",
      "redeem": "wl-fab-redeem",
    };
    this.appSettings.mobileResultPopupConfig = {
      filterIconSource: "fab/icons/filter-icon.svg",
      sortIconSource: "fab/icons/sort-icon.svg",
      mapIconSource: "fab/icons/icn-map.svg",
    };
    this.appSettings.dateFormat = MomentDateType.ll;
    this.appSettings.carsBookingPage.confirmedIcon = "/fab/icons/icn-confirmed.svg";
    this.appSettings.carsBookingPage.pendingIcon = "/fab/icons/icn-pending.svg";
    this.appSettings.carsBookingPage.bookingReferenceText = "wl.travel_id";

    this.$rootScope.globalState.hideUseAddressFromProfile = true;
    this.$rootScope.globalState.useSimpleLoader = false;
    this.$rootScope.abTest = { withConfidence: "no" };
    this.$rootScope.convert_rate = this.$rootScope.selectedCurrency.rate;
    this.$rootScope.landingPage.minBookingDays = 1;
    this.$rootScope.selectedCurrency.acceptAmex = false;
    this.$rootScope.globalState.serviceEmail = "contactus@my-travelcreditcard.com";

    this.appSettings.storeCreditCard = true;
    this.appSettings.hasCustomflightSummaryHeaderText = true;
    this.appSettings.customFlightLineIconUrl = "/fab/icons/icn-flightline.svg";
    this.appSettings.hasMultiplePaymentMethods = true;
    this.appSettings.sortSettings.canUseFullCashOnRedemption = true;
    this.appSettings.sortSettings.canUseFullPointsOnRedemption = true;
    this.appSettings.checkoutFormScrollOffset = 100;
    this.appSettings.dateIcon = "date-icon.svg";
    this.appSettings.flightsEnabledSortingOptions.push(...[FlightSortType.MILES_LOHI, FlightSortType.MILES_HILO]);
    this.appSettings.defaultCountryInCheckout = {
      hasDefaultCountry: true,
      defaultCountryCode: "AE",
    };
    this.appSettings.ccValidationUseCardErrorModal = true;
    this.appSettings.stripePaymentIntentsEnabled = true;

    if (this.globalStateService.isTravelType(TravelType.HOTELS)) {
      const hotelsEnabledSortingOptions: SortType[] = [SortType.DEAL, SortType.BEST_RATED, SortType.PAY_POINTS_LOHI,
      SortType.PAY_POINTS_HILO, SortType.DISTANCE_LOHI, SortType.PAY_POINTS_CASH_LOHI,
      SortType.PAY_POINTS_CASH_HILO, SortType.RATING_LOHI, SortType.RATING_HILO];
      this.searchSortingService.initializePermittedSortingOptions(hotelsEnabledSortingOptions);
    } else if (this.globalStateService.isTravelType(TravelType.CARS)) {
      const carsEnabledSortingOptions: SortType[] = [SortType.PAY_POINTS_LOHI,
      SortType.PAY_POINTS_HILO, SortType.PAY_POINTS_CASH_LOHI, SortType.PAY_POINTS_CASH_HILO];
      this.searchSortingService.initializeCarsPermittedSortingOptions(carsEnabledSortingOptions);
    }

    this.appSettings.iconFormat = "svg";
    this.appSettings.isTravelBreakdownFlexDirectionDefault = false;
    this.appSettings.checkoutSettings.addNewCardText = "wl.fab.travel_card";
    const selectedPointsPartner = this.$rootScope.pointsPartners.findById(
      this.$rootScope.landingPage.points_partner_id,
    );

    this.appSettings.pointsCashSliderSettings.sliderLabel = `Combine Cash & ${selectedPointsPartner.partner}`;
    this.$rootScope.alwaysShowPointsSlider = this.appSettings.pointsCashSliderSettings.alwaysShowPointsSlider;
    this.appSettings.pointsCashSliderSettings.sliderRightLabel = "100% Cash";
    this.appSettings.pointsCashSliderSettings.sliderLeftLabel = "0% Cash";
    this.$rootScope.globalState.requireLoginOnCheckout = true;
    this.$rootScope.globalState.carsRedemptionLabel = "Redemption Range";
    // Setting for FAB header title
    const capitalizedTravelType = this.globalStateService
      .travelType.replace(/^\w/, c => c.toUpperCase());

    this.$rootScope.globalState.activeHeaderTitle = capitalizedTravelType;

    this.$rootScope.globalState.loginEndpoint = "whitelabel/fab/login";
    if (this.kaligoConfig.isProduction) {
      this.$rootScope.globalState.loginEndpoint = "https://online.bankfab.com/PersonalBankingWeb/login";
    }

    if (this.$rootScope.isUserLoggedIn()) {
      this.hermesService.initHermes();
    }

    if (this.$rootScope.landingPage.hasProductType(ProductType.COMPLIMENTARY_NIGHTS)) {
      this.$rootScope.landingPage.minBookingDays = 4;
      this.$rootScope.globalState.useSimpleLoader = true;
      this.cityCountryBuilderService.buildList("common");
      this.appSettings.maximumGuestsMessage = {
        showMessage: true,
        maximumGuests: 4,
      };
    }
  }

}

angular.module("BookingApp").controller("FabCtrl", FabController);
